import * as React from "react"
import {Link} from "gatsby"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const SleepPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }
    useEffect(() => {
        Axios.get("/log.php?page=accommodation_"+lang);
    },[])


    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu changeLang={changeLang} lang={lang}/>

            <div id="duogram"></div>

            {lang != "en"&&
                <>
                    <h1>Overnatting</h1>

                    <p>
                        Via tjenesten AirBnb kan man finne utleie-leiligheter og <nobr>-hus</nobr> som ligger i umiddelbar nærhet til stedet bryllupsseremonien og festen skal foregå.

                       Under finnes også en liste med hoteller i området som kan være aktuelle.
                    </p>

                    <h2>Airbnb:</h2>
                    <p>
                        Det er flere sommerhus og/eller rom som leies ut i området via tjenesten {" "}
                        <Link target={"_blank"} to={"https://www.airbnb.no/s/Marathonas--%CE%91%CE%BD%CE%B1%CF%84%CE%BF%CE%BB%CE%B9%CE%BA%CE%AE-%CE%91%CF%84%CF%84%CE%B9%CE%BA%CE%AE/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&query=Marathonas%2C%20%CE%91%CE%BD%CE%B1%CF%84%CE%BF%CE%BB%CE%B9%CE%BA%CE%AE%20%CE%91%CF%84%CF%84%CE%B9%CE%BA%CE%AE&flexible_trip_lengths%5B%5D=weekend_trip&date_picker_type=calendar&checkin=2022-07-10&checkout=2022-07-12&source=structured_search_input_header&search_type=filter_change"}>Airbnb</Link>
                        {" "}
                        (trykk på lenken for å vise tilgjengelige steder).
                    </p>
                    <p>
                        Stedene nærmest bryllupsstedet ligger i Schinias (ved stranda).
                        Ikke langt unna er Marathon Beach (5-10 min med bil) og Nea Makri (10-15 min med bil). Andre områder som kan være aktuelle
                        er Dikastika og Zouberi.
                    </p>

                    <h2>Hoteller:</h2>

                    <h3>Golden Coast, Marathon</h3>
                    <p>
                        Dette er hotellet som ligger nærmest bryllupsstedet.
                    </p>
                    <p>
                        Lenke til hotellet: <Link target="_blank" to={"https://www.goldencoast.gr/en/"}>trykk her</Link>
                    </p>

                    <h3>Thomas Beach Hotel, Nea Makri</h3>
                    <p>
                        Lenke til hotellet: <Link target="_blank" to={"https://www.thomasbeachhotel.com.gr/portal.php?action=homepage&language=en"}>trykk her</Link>
                    </p>

                    <h3>Marathon Beach Resort, Nea Makri</h3>
                    <p>
                        Booking via e-post titulert “Nina & Knut Wedding”
                    </p>
                    <p>
                        Lenke til hotellet: <Link target="_blank" to={"https://www.marathonbeachresort.com/en"}>trykk her</Link>
                    </p>

                    <h3>Cabo Verde, Nea Makri</h3>
                    <p>
                        Booking code “Nina & Knut Wedding July 22”
                    </p>
                    <p>
                        Lenke til hotellet: <Link target="_blank" to={"https://www.caboverde.gr/"}>trykk her</Link>
                    </p>
                </>
            }
            {lang == "en" &&
                <>
                    <h1>Accommodation</h1>

                    <p>
                        There are some houses and rooms available through the service AirBNB that are close to the location of the wedding ceremony and party.

                        Below we have also listed hotels in the area.
                    </p>

                    <h2>Airbnb:</h2>
                    <p>
                        There are several summer houses available via the service {" "}
                        <Link target={"_blank"} to={"https://www.airbnb.no/s/Marathonas--%CE%91%CE%BD%CE%B1%CF%84%CE%BF%CE%BB%CE%B9%CE%BA%CE%AE-%CE%91%CF%84%CF%84%CE%B9%CE%BA%CE%AE/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&query=Marathonas%2C%20%CE%91%CE%BD%CE%B1%CF%84%CE%BF%CE%BB%CE%B9%CE%BA%CE%AE%20%CE%91%CF%84%CF%84%CE%B9%CE%BA%CE%AE&flexible_trip_lengths%5B%5D=weekend_trip&date_picker_type=calendar&checkin=2022-07-10&checkout=2022-07-12&source=structured_search_input_header&search_type=filter_change"}>Airbnb</Link>
                        {" "}
                        (press the link to view available properties).
                    </p>
                    <p>
                        Areas of interest are Schinias (by the beach), Marathon Beach (5-10 mins away by car) and Nea Makri (10-15 mins away by car).
                        Dikastika could also be an alternative that is not too far away.
                    </p>

                    <h2>Hotels:</h2>

                    <h3>Golden Coast, Marathon</h3>
                    <p>
                        The closest hotel to the ceremony and party.
                    </p>
                    <p>
                        Link to the hotel: <Link target="_blank" to={"https://www.goldencoast.gr/en/"}>press here</Link>
                    </p>

                    <h3>Thomas Beach Hotel, Nea Makri</h3>
                    <p>
                        Link to the hotel: <Link target="_blank" to={"https://www.thomasbeachhotel.com.gr/portal.php?action=homepage&language=en"}>press here</Link>
                    </p>

                    <h3>Marathon Beach Resort, Nea Makri</h3>
                    <p>
                        Booking via e-mail with the title “Nina & Knut Wedding”
                    </p>
                    <p>
                        Link to the hotel: <Link target="_blank" to={"https://www.marathonbeachresort.com/en"}>press here</Link>
                    </p>

                    <h3>Cabo Verde, Nea Makri</h3>
                    <p>
                        Booking code “Nina & Knut Wedding July 22”
                    </p>
                    <p>
                        Link to the hotel: <Link target="_blank" to={"https://www.caboverde.gr/"}>press here</Link>
                    </p>

                </>
            }

        </main>
    )
}

export default SleepPage
